<template>
  <div class="d-flex flex-column">
    <v-select
      v-model="computedBackupMode"
      :items="backupModes"
      item-text="text"
      item-value="id"
      :label="$t('t.Actions')"
      :hide-details="true"
    />
    <label-picker
      class="mt-4"
      :selection.sync="computedLabels"
      :attach="false"
    />
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  components: {
    LabelPicker: () => import('@/components/label-picker')
  },
  computed: {
    computedLabels: {
      get () {
        return this.dataLabels
      },
      set (labels) {
        this.dataLabels = labels
        this.dataDocumentEmit()
      }
    },
    computedBackupMode: {
      get () {
        return this.dataBackupMode
      },
      set (backupMode) {
        this.dataBackupMode = backupMode
        this.dataDocumentEmit()
      }
    }
  },
  data () {
    return {
      backupModes: [{ id: 'ADD', text: this.$t('t.Add') }, { id: 'REMOVE', text: this.$t('t.Delete') }, { id: 'REPLACE', text: this.$t('t.Replace') }],
      dataLabels: [],
      dataBackupMode: 'ADD',
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      required: [v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')]
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        type: 'set-labels-link',
        labelIds: this.dataLabels,
        backupMode: this.dataBackupMode
      }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataLabels = v?.labelIds ? this.lodash.cloneDeep(v.labelIds) : []
        this.dataBackupMode = v?.backupMode ?? 'ADD'
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
